import React from "react";
import FAQSection from "../../../Common/FAQSection/FAQSection";

export const discoveryFAQList = [
    {
        title: "What is a discovery phase?",
        iconPath: [null],
        description: (
            <>
                A discovery phase is a process at the initial stages of a
                project where research is conducted to gather information,
                identify goals, define requirements, and assess feasibility. The
                discovery phase service for software development lays the
                foundation for the project and provides a clear understanding of
                the problem to be solved and the required solution.
            </>
        ),
    },
    {
        title: "Who needs a discovery phase?",
        iconPath: [null],
        description: (
            <>
                A discovery phase is beneficial for any person or organization
                starting a new project, whether it&apos;s a business initiative,
                a software development project, a marketing campaign, or any
                other type of project. Seeking discovery phase services is
                particularly important for complex or innovative projects where
                there is a higher degree of uncertainty or risk involved.
                Ultimately, the discovery phase can save time, money, and
                resources by identifying potential issues early on and allowing
                for adjustments to be made before the project is fully underway.
            </>
        ),
    },
    {
        title: "What is included in discovery phase?",
        iconPath: [null],
        description: (
            <>
                Usually, a discovery phase includes research, stakeholder
                interviews, requirements gathering, analysis of user needs,
                feasibility studies, and prototyping. The specific activities
                and methods used may vary depending on the nature of the
                project, but the goal is to gather information, identify goals,
                define requirements, and assess feasibility in order to lay the
                foundation for the project and provide a clear understanding of
                the problem to be solved and the solution that is required.
            </>
        ),
    },
    {
        title: "How do you do a discovery phase?",
        iconPath: [null],
        description: (
            <>
                Speaking of discovery phase service for software development,
                you might usually expect this process to include these general
                steps:
                <br />
                - Identification of the project goals and objectives
                <br />
                - Formation of a discovery team
                <br />
                - Research and analysis
                <br />
                - User needs identification and prioritization
                <br />
                - Definition of the project requirements and constraints
                <br />
                - Exploration of the potential solutions
                <br />
                - Development and testing of the prototypes
                <br />
                - Evaluation of the feasibility and risks
                <br />
                - Creation of a project plan and roadmap
                <br />
                The specific activities and methods used will vary depending on
                the nature of the project and the goals of the discovery phase.
            </>
        ),
    },
    {
        title: "What are the upsides of outsourcing my discovery phase?",
        iconPath: [null],
        description: (
            <>
                    The upsides of outsourcing the discovery phase of a project
                    include:
                    <br />
                    - Access to specialized expertise, knowledge, skills, and tools
                    that may not be available in-house.
                    <br />
                    - Hiring a third party for discovery phase services can often
                    result in faster completion of the discovery phase.
                    <br />
                    - Outsourcing the discovery phase can also result in cost
                    savings, as the external team can be more efficient and
                    cost-effective.
                    <br />
                    - Using the discovery phase services of an external team can
                    bring a fresh perspective and objective viewpoint to the
                    project, helping to avoid biases and assumptions.
                    <br />
                    - The organization can reduce the risk of making costly mistakes
                    or misjudgments, as the external team can provide a more
                    comprehensive analysis of the project requirements and
                    constraints.
            </>
        ),
    },
    {
        title: "What do you get at the end of a discovery phase?",
        iconPath: [null],
        description: (
            <>
                When you order discovery phase service for software development,
                as a result, you typically get a comprehensive understanding of
                the problem you are trying to solve, as well as a clear
                definition of the project goals, requirements, constraints, and
                risks. You may also have developed prototypes, conducted user
                research and analysis, and explored potential solutions to the
                problem. The outcome of the discovery phase should provide a
                foundation for the project plan and roadmap, and inform the
                decision-making process for the rest of the project.
            </>
        ),
    },
];

function DiscoveryFAQSection() {
    return <FAQSection title="FAQ" FAQList={discoveryFAQList} />;
}

export default DiscoveryFAQSection;
